<template lang="pug">
  .course-appbar(v-click-outside="changeShowing")
    .course-appbar__mobile(v-if="!isCourseLeadersAccess")
      app-button(icon iconLarge @click.native="toggle").unselectable
        <i v-if="!showing" class="material-icons">apps</i>
        <i v-else class="material-icons">clear</i>

    .course-appbar__container(v-if="!isCourseLeadersAccess" :class="{opened: showing}")
      .appsbar__wrapper
        crudDialog(@create="needToUpdate" @click:remove="needToUpdate" @update="needToUpdate")
          template(v-slot:activator="createCourse")
            app-button(@click.native="createCourse.toggle" hide-shadow).head-btn.ml-2 Create Course

        journey-crud(@click:create="needToUpdate" @click:remove="needToUpdate" @click:update="needToUpdate" :activeCity="activeCity")
          template(v-slot:activator="createJourney")
            app-button(@click.native="createJourney.open" hide-shadow).head-btn.ml-2 Add Journey

        journeyManagement(@needToUpdate="needToUpdate")
          template(v-slot:activator="createJourney")
            app-button(@click.native="createJourney.open" hide-shadow).head-btn.ml-2 Journeys List

    portal-target(name="course-appbar")
</template>

<script>
import vClickOutside from 'v-click-outside'
import showingMixin from '@/mixins/showing.mixin'
import permsMixin from "@/mixins/perms.mixin"
import appButton from '@/components/global/actions/BaseButton.vue'

export default {
  name: 'CourseAndJourneyCreateButtons',

  directives: {
    clickOutside: vClickOutside.directive
  },

  props: {
    activeCity: Number
  },

  mixins: [permsMixin, showingMixin],

  components: {
    appButton,
    crudDialog: () => import('@/app/admin/modules/course_managment/components/curssusen/cm_configuration/CourseDayCrud.vue'),
    journeyCrud: () => import('@/app/admin/modules/course_day/components/journey/JourneyCRUD.vue'),
    journeyManagement: () => import('@/app/admin/modules/course_day/components/journey/JourneyManagement.vue')
  },

  methods: {
    needToUpdate() {
      this.$emit('needToUpdate', true)
    },
    changeShowing() {
      this.updateShowing(false)
    }
  }
}
</script>

<style lang="scss" scoped>
.course-appbar {
  display: flex;

  &__container {
    display: flex;

    @include media("<=tablet") {
      display: none;
      position: fixed;
      background-color: white;
      width: calc(100% - 45px);
      padding: 10px;
      right: 0;
      top: 49px;
    }

    &.opened {
      display: flex;
    }

    .appsbar__wrapper {
      display: flex;
      flex-direction: row ;
      justify-content: center;
      margin: 0 auto;

      @include media("<=tablet") {
        width: 100%;
        flex-direction: column;
        border-bottom: 1px solid $border-color;
      }
    }
  }

  &__mobile {
    display: none;

    @include media("<=tablet") {
      display: flex;
    }
  }
}
</style>
